import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Row, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import Accordion from "react-bootstrap/Accordion";
import Layout from "../components/layout";

import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { repositoryConfigs } from "../utils/prismicPreviews";
import { RichText } from "prismic-reactjs";

function HomepageTemplate({ data, pageContext: { lang, currentPath } }) {
  var singletext = data.prismicHomepage.data;

  const [validated, setValidated] = React.useState(true);

  useEffect(() => {
    var previousScroll = 0;
    var animationStarted = false;

    document
      .getElementById("depositevalue")
      .addEventListener("change", validateDepositAmount);
    document
      .getElementById("depositevalue")
      .addEventListener("keyup", validateDepositAmount);

    function validateDepositAmount(e) {
      var depositevalue = document.getElementById("depositevalue").value;
      if (depositevalue >= 100) {
        setValidated(true);
      } else {
        setValidated(false);
      }
    }

    window.addEventListener("scroll", function (event) {
      if (window.scrollY >= document.getElementById("safety").offsetTop - 380) {
        document.getElementById("safety").classList.toggle("animate-safety");
      }
      if (animationStarted) {
        return false;
      }
      var scroll = window.scrollY;
      var top = document.getElementById("coinsgrow").offsetTop;
      var shouldAnimate = scroll >= top - 180;
      var shouldStart = shouldAnimate && previousScroll < top - 180;
      var coins = document.querySelectorAll(
        "#first-coin,#second-coin,#third-coin,#left0-coin,#left1-coin,#right0-coin,#right1-coin,#right2-coin"
      );
      if (shouldAnimate) {
        document.getElementById("coinsgrow").classList.toggle("animate-grow");
      }
      if (shouldStart) {
        for (var i = 0; i < coins.length; i++) {
          coins[i].classList.remove("remove-coins");
        }
        var timers = [
          setTimeout(function () {
            document
              .getElementById("first-coin")
              .classList.add("fade-in-coins");
          }, 150),
          setTimeout(function () {
            document
              .getElementById("second-coin")
              .classList.add("fade-in-coins");
          }, 300),
          setTimeout(function () {
            document
              .getElementById("third-coin")
              .classList.add("fade-in-coins");
          }, 450),
          setTimeout(function () {
            document
              .getElementById("left0-coin")
              .classList.add("fade-in-coins");
          }, 600),
          setTimeout(function () {
            document
              .getElementById("left1-coin")
              .classList.add("fade-in-coins");
          }, 750),
          setTimeout(function () {
            document
              .getElementById("right0-coin")
              .classList.add("fade-in-coins");
          }, 900),
          setTimeout(function () {
            document
              .getElementById("right1-coin")
              .classList.add("fade-in-coins");
          }, 1050),
          setTimeout(function () {
            document
              .getElementById("right2-coin")
              .classList.add("fade-in-coins");
          }, 1200),
        ];
        animationStarted = true;
      }
      previousScroll = scroll;
    });

    document
      .getElementById("depositevalue")
      .addEventListener("change, keypress, keyup", (e) => {
        var txt = String.fromCharCode(e.which);
        var depositevalue = document.getElementById("depositevalue").value;
        document.getElementById("deposite").innerHTML = depositevalue;

        var monthval = document.getElementById("monthvalue").value;

        if (monthval == 12) {
          var price = depositevalue * 1.1;
          document.getElementById("totalamount").innerHTML = price.toFixed(0);
          var bonus = 1;
          document.getElementById("bonus").innerHTML = bonus;
        }

        if (monthval == 24) {
          var price = depositevalue * 1.2;
          document.getElementById("totalamount").innerHTML = price.toFixed(0);
          var bonus = 2;
          document.getElementById("bonus").innerHTML = bonus;
        }

        if (monthval == 36) {
          var price = depositevalue * 1.3;
          document.getElementById("totalamount").innerHTML = price.toFixed(0);
          var bonus = 3;
          document.getElementById("bonus").innerHTML = bonus;
        }
        if (e.keyCode === 190) {
          e.preventDefault();
        }
        if (!txt.match(/[A-Za-z0-9&. ]/)) {
          return false;
        }
      });
    function ForNumbers(evt) {
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105) ||
        charCode == 8 ||
        charCode == 9 ||
        charCode == 13 ||
        (charCode >= 35 && charCode <= 46)
      ) {
        if (parseInt(this.value + String.fromCharCode(charCode), 10) <= 9999999)
          return true;
      }
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }

    document
      .getElementById("depositevalue")
      .addEventListener("change", ForNumbers, false);
    document
      .getElementById("depositevalue")
      .addEventListener("keypress", ForNumbers, false);
    document
      .getElementById("depositevalue")
      .addEventListener("keyup", ForNumbers, false);
    document.getElementById("depositevalue").addEventListener(
      "input",
      (e) => {
        var monthval = document.getElementById("monthvalue").value;
        document.getElementById("month").innerHTML = monthval;

        var depositevalue = document.getElementById("depositevalue").value;
        document.getElementById("deposite").innerHTML = depositevalue;

        if (monthval == 12) {
          var price = depositevalue * 1.01;
          document.getElementById("totalamount").innerHTML = price.toFixed(2);
          var bonus = 1;
          document.getElementById("bonus").innerHTML = bonus;
        }

        if (monthval == 24) {
          var price = depositevalue * 1.02;
          document.getElementById("totalamount").innerHTML = price.toFixed(2);
          var bonus = 2;
          document.getElementById("bonus").innerHTML = bonus;
        }

        if (monthval == 36) {
          var price = depositevalue * 1.03;
          document.getElementById("totalamount").innerHTML = price.toFixed(2);
          var bonus = 3;
          document.getElementById("bonus").innerHTML = bonus;
        }
      },
      false
    );

    document
      .getElementById("monthvalue")
      .addEventListener("change, keypress, keyup", ForNumbers, false);
    document.getElementById("monthvalue").addEventListener("change", (e) => {
      var monthval = document.getElementById("monthvalue").value;
      document.getElementById("month").innerHTML = monthval;

      var depositevalue = document.getElementById("depositevalue").value;
      document.getElementById("deposite").innerHTML = depositevalue;

      if (monthval == 12) {
        var price = depositevalue * 1.01;
        document.getElementById("totalamount").innerHTML = price.toFixed(0);
        var bonus = 1;
        document.getElementById("bonus").innerHTML = bonus;
      }

      if (monthval == 24) {
        var price = depositevalue * 1.02;
        document.getElementById("totalamount").innerHTML = price.toFixed(0);
        var bonus = 2;
        document.getElementById("bonus").innerHTML = bonus;
      }

      if (monthval == 36) {
        var price = depositevalue * 1.03;
        document.getElementById("totalamount").innerHTML = price.toFixed(0);
        var bonus = 3;
        document.getElementById("bonus").innerHTML = bonus;
      }
    });
  }, []);

  return (
    <Layout
      wpLang={lang}
      currentPath={currentPath}
      featuredImagePath="above-min.png"
      pageTitle={singletext.above_the_fold_title.text}
      pageMeta={singletext.above_the_fold_description.text}
    >
      <Row className="hero">
        <div className="container">
          <div className="col-lg-12">
            <div className="hero__text-holder">
              <h1 className="hero__title hero__title--boxed">
                {singletext.above_the_fold_title.text}
              </h1>
              <h2 className="hero__subtitle">
                {singletext.above_the_fold_description.text}
              </h2>
            </div>
            <div className="grow-form">
              <form>
                <div className="form-one">
                  <p>{singletext.amount_to_deposit.text}</p>
                  <label className="dag-text">
                    <input
                      id="depositevalue"
                      type="number"
                      name="quantity"
                      min="100"
                      max="9999999"
                      placeholder="1000"
                      className={validated ? "" : "error-input"}
                    />
                    {!validated && (
                      <p className="error-message">Minimum amount is 100</p>
                    )}
                  </label>
                </div>
                <div className="form-one">
                  <p>{singletext.duration.text}</p>
                  <select id="monthvalue">
                    <option value="12">12 {singletext.months.text}</option>
                    <option value="24">24 {singletext.months.text}</option>
                    <option value="36">36 {singletext.months.text}</option>
                  </select>
                </div>
                <div className="results">
                  <div className="one-row">
                    <div className="first-a">
                      {singletext.amount_to_deposit.text}
                    </div>
                  </div>
                  <div className="one-row">
                    <div className="first-b">
                      <span id="deposite">1010</span> DAG
                    </div>
                  </div>
                  <div className="one-row">
                    <div className="first-a">{singletext.period.text}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b">
                      <span id="month">12</span> {singletext.months.text}
                    </div>
                  </div>
                  <div className="one-row">
                    <div className="first-a">{singletext.bonus_rate.text}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b">
                      <span id="bonus">1</span>%
                    </div>
                  </div>
                </div>
                <div className="more">
                  <div className="one-row">
                    <div className="first-a">{singletext.you_ll_get.text}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b">
                      <span id="totalamount">1010</span> DAG
                    </div>
                  </div>
                </div>
                <div className="final">
                  <a href={singletext.start_now_link.url}>
                    {singletext.start_growing.text}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
      <Row className="partone pbt260">
        <div className="container">
          <div className="col-lg-12">
            <h3>{singletext.second_title.text}</h3>
            <h2>{singletext.third_title.text}</h2>
            <ul>
              {singletext.how_it_works.map((value, index) => {
                return (
                  <li>
                    <img src={value.image_how_it_works.fixed.src} />
                    <p className="title-list">
                      {value.title_how_it_works.text}
                    </p>
                    <p>{value.how_it_works_description.text}</p>
                  </li>
                );
              })}
            </ul>
            <a className="sign-up-btn" href={singletext.start_now_link.url}>
              {singletext.start_now_button.text}
            </a>
          </div>
        </div>
      </Row>
      <section id="coinsgrow" className="sections animate-grow">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <div className="content-img order1">
                <div className="coins-stack">
                  <div className="coins-wrapper">
                    <img
                      id="left0-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="left1-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="default-coin"
                      src="https://downloads.dagcoin.org/files/default-coin.svg"
                    />
                    <img
                      id="first-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="second-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="third-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="right0-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="right1-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                    <img
                      id="right2-coin"
                      src="https://downloads.dagcoin.org/files/coin.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="content-text order2">
                <div className="content-wrap">
                  <RichText render={singletext.how_to_grow_coins.raw} />
                  <a
                    className="sign-up-btn"
                    href={singletext.start_now_link.url}
                  >
                    {singletext.start_growing.text}
                  </a>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section id="safety" className="sections animate-safety">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <div className="content-img order2">
                <div className="lock-stack">
                  <div className="lock-wrapper">
                    <div className="locked"></div>
                    <img
                      className="lock"
                      src="https://downloads.dagcoin.org/files/lock.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="content-text order1">
                <div className="content-wrap">
                  <RichText
                    render={singletext.your_safety_is_our_priority.raw}
                  />
                  <a
                    className="sign-up-btn"
                    href={singletext.start_now_link.url}
                  >
                    {singletext.sign_up_button.text}
                  </a>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section id="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>{singletext.frequently_asked_questions.text}</h3>
              <div className="faq-content">
                {singletext.frequently_asked_questions_content.map(
                  (value, index) => {
                    return (
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              {value.question_title.text}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <RichText render={value.question_aswer.raw} />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                  }
                )}
              </div>

              <div className="support-link">
                {" "}
                <RichText render={singletext.didn_t_find_an_answer.raw} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partone lastpart">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <h2>{singletext.good_time_to_start_was_yesterday.text}</h2>
              <ul>
                {singletext.good_time_section.map((value, index) => {
                  return (
                    <li>
                      <img src={value.image_goodtime.fixed.src} />
                      <p className="title-list">{value.title_good_time.text}</p>
                      <p>{value.good_time_description.text}</p>
                    </li>
                  );
                })}
              </ul>

              <a className="sign-up-btn" href={singletext.start_now_link.url}>
                {singletext.start_growing.text}
              </a>
            </div>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      id
      data {
        above_the_fold_description {
          text
          raw
          html
        }
        above_the_fold_title {
          html
          raw
          text
        }
        amount_to_deposit {
          html
          raw
          text
        }
        bonus_rate {
          html
          raw
          text
        }
        didn_t_find_an_answer {
          html
          raw
          text
        }
        duration {
          html
          raw
          text
        }
        frequently_asked_questions {
          html
          raw
          text
        }
        good_time_section {
          good_time_description {
            html
            raw
            text
          }
          image_goodtime {
            fixed {
              src
            }
          }
          title_good_time {
            html
            raw
            text
          }
        }
        frequently_asked_questions_content {
          question_aswer {
            html
            raw
            text
          }
          question_title {
            html
            raw
            text
          }
        }
        good_time_to_start_was_yesterday {
          html
          raw
          text
        }
        how_it_works {
          how_it_works_description {
            html
            raw
            text
          }
          image_how_it_works {
            fixed {
              src
            }
          }
          title_how_it_works {
            html
            raw
            text
          }
        }
        how_to_grow_coins {
          html
          raw
          text
        }
        initial_deposit {
          raw
          text
          html
        }
        months {
          html
          raw
          text
        }
        period {
          html
          raw
          text
        }
        second_title {
          html
          raw
          text
        }
        seo_meta {
          html
          raw
          text
        }
        seo_title {
          html
          raw
          text
        }
        sign_up_button {
          html
          raw
          text
        }
        start_growing {
          html
          raw
          text
        }
        start_growing_button {
          html
          raw
          text
        }
        start_now_button {
          html
          raw
          text
        }
        start_now_link {
          url
        }
        third_title {
          html
          raw
          text
        }
        you_ll_get {
          html
          raw
          text
        }
        your_safety_is_our_priority {
          html
          raw
          text
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
      _previewable
      url
      lang
    }
  }
`;

export default withPrismicPreview(HomepageTemplate, repositoryConfigs);
